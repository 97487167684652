import { Component } from '@angular/core';
import { map } from 'rxjs/operators';
import { NavigationStart, NavigationEnd, NavigationCancel, NavigationError, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { PwaService } from './shared/services/pwa.service';

@Component({
  selector: 'app-root',
  template: `
    <div>
      <loading-spinner *ngIf="loading"></loading-spinner>
      <router-outlet></router-outlet>
    </div>
  `,
  styles: [
    `
      div {
        height: 100vh;
        overflow: hidden;
      }
    `
  ]
})
export class AppComponent {
  loading: boolean = true;
  loading$: Observable<boolean> = this.router.events.pipe(
    map(event => {
      if (event instanceof NavigationStart) {
        return (this.loading = true);
      } else if (event instanceof NavigationEnd) {
        return (this.loading = false);
      } else if (event instanceof NavigationCancel) {
        return (this.loading = false);
      } else if (event instanceof NavigationError) {
        return (this.loading = false);
      }
    })
  );

  constructor(
    private router: Router,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private pwaService: PwaService
  ) {
    this.loading$.subscribe();
    this.matIconRegistry
      .addSvgIcon('sympliSYNC', this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/images/sympliSYNC.svg`))
      .addSvgIcon('patients', this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/images/patients.svg`));
  }
}
