import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class AssetInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return req.url.includes('.svg')
      ? next.handle(
          req.clone({
            url: req.url.slice(req.url.indexOf(environment.baseUrl) + environment.baseUrl.length)
          })
        )
      : next.handle(req);
  }
}
