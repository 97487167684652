import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

export class GraphQLInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return req.url.includes('graphql')
      ? next.handle(
          req.clone({
            url: `${environment.domainUrl}/graphql`,
            headers: req.headers.has('Key-Inflection') ? req.headers.delete('Key-Inflection') : req.headers
          })
        )
      : next.handle(req);
  }
}
