import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AuthService } from '../../home/shared/services/auth.service';
import { MatSnackBar as MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { ServerMaintenanceDialogComponent } from '../components/server-maintenance-dialog/server-maintenance-dialog.component';

@Injectable({ providedIn: 'root' })
export class LogInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError(event => {
        if (event instanceof HttpErrorResponse && event.status === 401) {
          this.authService.logout();
          this.snackBar.open('Unauthorized. Please Login.', null, {
            duration: 5000,
            panelClass: 'error-toast'
          });
        } else if (event.status === 408) {
          this.snackBar.open('Network timeout occurred.', null, {
            duration: 5000,
            panelClass: 'error-toast'
          });
        } else if (event instanceof HttpErrorResponse && event.status === 400) {
          this.snackBar.open(`${event.error.errors}`, null, {
            duration: 5000,
            panelClass: 'error-toast'
          });
        } else if (event instanceof HttpErrorResponse && event.status === 500) {
          this.snackBar.open('Server Error Occurred.', null, {
            duration: 5000,
            panelClass: 'error-toast'
          });
        } else if (event instanceof HttpErrorResponse && event.status === 503) {
          this.dialog.open(ServerMaintenanceDialogComponent, {
            disableClose: true
          });
        }
        return throwError(() => event);
      })
    );
  }
}
