import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

export class MultiPartInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return req.headers.has('multipart')
      ? next.handle(
          req.clone({
            headers: req.headers.delete('Content-Type')
          })
        )
      : next.handle(req);
  }
}
