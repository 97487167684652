import { NgModule } from '@angular/core';
import { ServerMaintenanceDialogComponent } from './server-maintenance-dialog.component';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  imports: [CommonModule, MatDialogModule],
  exports: [ServerMaintenanceDialogComponent],
  declarations: [ServerMaintenanceDialogComponent],
  providers: []
})
export class ServerMaintenanceDialogModule {}
